export type EventDescriptionItem = {
  title: string
  description: string
}

export type EventRewardsItem = {
  line: number
  title: string
}

export type EventEntryFilter = {
  age: boolean
  gender: boolean
  firstGachi: boolean
  registMatch: boolean
  artistGroup: boolean
}

export type SubEventItem = {
  eventId: number
  eventDetailId: number
  title: string
  eventStartAt: string
  eventEndAt: string
  isRankingFinalized: number
  descriptions: EventDescriptionItem[]
  rewards: EventRewardsItem[]
}

export type FanRankingItem = {
  rank: number
  score: number
  fan: {
    id: number
    name: string
    iconUrl: string
  }
}

export type EventRankingItem = {
  rank: number
  score: number
  artist: {
    id: number
    fanId: number
    name: string
    iconUrl: string
    isFollow: number
    fanRankings: FanRankingItem[]
  }
}

export const EventType = {
  GACHI: { value: 'GACHI', text: 'ガチイベ', color: 'primary' },
  YURU: { value: 'YURU', text: 'ゆるイベ', color: 'blue' },
} as const
export type EventTypeType = keyof typeof EventType

export type EventRecruitItem = {
  eventId: number
  eventDetailId: number
  title: string
  bannerUrl: string
  iconUrl: string
  type: EventTypeType
  eventDisplayStartAt: string
  eventDisplayEndAt: string
  entryStartAt: string
  entryEndAt: string
  eventStartAt: string
  eventEndAt: string
  isMain: number
  isRankingFinalized: number
  isEntry: number
  descriptions: EventDescriptionItem[]
  rewards: EventRewardsItem[]
  entryFilter: EventEntryFilter
}

export type EventOpenItem = {
  eventId: number
  eventDetailId: number
  title: string
  bannerUrl: string
  iconUrl: string
  type: EventTypeType
  eventDisplayStartAt: string
  eventDisplayEndAt: string
  entryStartAt: string
  entryEndAt: string
  eventStartAt: string
  eventEndAt: string
  isMain: number
  isRankingFinalized: number
  isEntry: number
  descriptions: EventDescriptionItem[]
  rewards: EventRewardsItem[]
  subEvents: SubEventItem[]
  rankings: EventRankingItem[]
}

export type EventFinishedItem = {
  eventId: number
  eventDetailId: number
  title: string
  bannerUrl: string
  iconUrl: string
  type: EventTypeType
  eventDisplayStartAt: string
  eventDisplayEndAt: string
  entryStartAt: string
  entryEndAt: string
  eventStartAt: string
  eventEndAt: string
  isMain: number
  isRankingFinalized: number
  isEntry: number
  descriptions: EventDescriptionItem[]
  rewards: EventRewardsItem[]
  subEvents: SubEventItem[]
  rankings: EventRankingItem[]
}

export type EventDetail = {
  eventId: number
  eventDetailId: number
  title: string
  bannerUrl: string
  iconUrl: string
  type: EventTypeType
  eventDisplayStartAt: string
  eventDisplayEndAt: string
  entryStartAt: string
  entryEndAt: string
  eventStartAt: string
  eventEndAt: string
  isMain: number
  isRankingFinalized: number
  isEntry: number
  descriptions: EventDescriptionItem[]
  rewards: EventRewardsItem[] | null
  subEvents: SubEventItem[]
  entryFilter: EventEntryFilter
}

export type EventEntryArtistItem = {
  id: number
  fanId: number
  name: string
  iconUrl: string
}
