'use client'

import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide'
import type { Route } from 'next'
import Link from 'next/link'
import { useRef, useState } from 'react'
import { BannerDestination, type BannerItem } from '../../types'
import styles from './SliderBanner.module.scss'
import '@splidejs/react-splide/css'

function SliderBanner({ banners }: { banners: BannerItem[] }) {
  const [activeIndex, setActiveIndex] = useState(0)
  const splideRef = useRef<Splide>(null)
  const bannerUrl = (banner: BannerItem) => {
    switch (banner.destination) {
      case BannerDestination.WEB.value:
        return banner.web ? banner.web.url : '/'
      case BannerDestination.EVENT_DETAIL.value:
        return banner.event ? `/events/${banner.event.eventDetailId}` : '/'
      case BannerDestination.ARTIST_PROFILE.value:
        return banner.artist ? `/artists/${banner.artist.fanId}` : '/'
      default:
        return ''
    }
  }

  return (
    <div className={styles.componentStyle}>
      <Splide
        className={styles.splide}
        options={{
          autoplay: true,
          type: 'loop',
          arrows: false,
          pagination: false,
          pauseOnHover: false,
          perMove: 1,
          gap: '8px',
          fixedWidth: '46%',
          width: '100%',
        }}
        onMove={({ index }) => {
          setActiveIndex(index)
        }}
        aria-label='Splide Basic HTML Example'
        hasTrack={false}
        ref={splideRef}
      >
        <SplideTrack className={styles.splide}>
          {banners.map((banner) => (
            <SplideSlide key={banner.id}>
              <Link
                href={bannerUrl(banner) as Route}
                className={styles.itemLink}
                prefetch={false}
              >
                <img
                  src={banner.bannerUrl}
                  alt={banner.bannerUrl}
                  className={styles.itemImg}
                />
              </Link>
            </SplideSlide>
          ))}
        </SplideTrack>
      </Splide>
      <div className={styles.positions}>
        {banners.map((banner, index) => (
          <div
            key={`position_${banner.id}`}
            className={`${styles.positionItem} ${
              activeIndex === index ? styles.active : ''
            }`}
          />
        ))}
      </div>
    </div>
  )
}

export default SliderBanner
