'use client'

import ImageView from '../ImageView/ImageView'
import ModalBase from '../ModalBase/ModalBase'
import styles from './AppInductionModal.module.scss'
import bannerAppStore from '@/assets/images/banner/banner-app-store.png'
import bannerGooglePlay from '@/assets/images/banner/banner-google-play.png'
import utilsStyles from '@/styles/modules/utils.module.scss'

function AppInductionModal({
  isOpen,
  onClose,
}: {
  isOpen: boolean
  onClose: VoidFunction
}) {
  return (
    <ModalBase isOpen={isOpen} onClose={onClose}>
      <div className={styles.modalContentsContainer}>
        <div className={styles.logoContainer}>
          <ImageView src='/cz-logo.png' alt='logo' isFreeSize />
        </div>
        <p
          className={`${utilsStyles.primaryColor} ${utilsStyles.textBold} ${utilsStyles.textXLarge} ${utilsStyles.textCenter} ${utilsStyles.mtSmall}`}
        >
          アプリをダウンロードして
          <br />
          アーティストを応援しよう！
        </p>
        <p className={styles.description}>
          CHEERZ（チアーズ）は
          <br />
          一般の女の子からアイドル、モデルまで、
          <br />
          様々なアーティストが毎日活動中！
          <br />
        </p>
        <div className={styles.linkButtonContainer}>
          <div className={styles.buttonDescriptionContainer}>
            <p className={styles.buttonDescription}>＜iOS端末をお持ちの方＞</p>
            <a href={process.env.NEXT_PUBLIC_APP_STORE_URL}>
              <img
                src={bannerAppStore.src}
                alt='appStore'
                className={styles.appBanner}
              />
            </a>
          </div>
          <div className={styles.buttonDescriptionContainer}>
            <p className={styles.buttonDescription}>
              ＜Android端末をお持ちの方＞
            </p>
            <a href={process.env.NEXT_PUBLIC_GOOGLE_PLAY_STORE_URL}>
              <img
                src={bannerGooglePlay.src}
                alt='googlePlay'
                className={styles.appBanner}
              />
            </a>
          </div>
        </div>
      </div>
    </ModalBase>
  )
}

export default AppInductionModal
