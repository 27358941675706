import { formatInTimeZone } from 'date-fns-tz'
import { dateFns, type DateFormat, newDate } from '@/lib/dateFns'

/**
 * 汎用日時フォーマッター
 * @param value
 * @returns
 */
export const formatDateCommon = (
  value: string | Date,
  format: DateFormat,
  isJST = false,
) => {
  if (!value) return ''
  if (typeof value === 'string') {
    return isJST
      ? formatInTimeZone(newDate(value), 'Asia/Tokyo', format)
      : dateFns.format(newDate(value), format)
  }
  return isJST
    ? formatInTimeZone(value, 'Asia/Tokyo', format)
    : dateFns.format(value, format)
}

/**
 * 数値フォーマッター（金額など）
 * @param value
 * @param blank2Zero
 * @returns
 */
export const formatCurrency = (
  value: string | number,
  blank2Zero?: boolean,
) => {
  if (value == null) {
    return blank2Zero ? '0' : ''
  }
  if (typeof value === 'string') {
    return Number.parseInt(value, 10).toLocaleString()
  }
  return value.toLocaleString()
}
