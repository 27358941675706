import styles from './ImageView.module.scss'

type Props = {
  src?: string
  alt?: string
  isFreeSize?: boolean
  isCircled?: boolean
  style?: React.CSSProperties
  loading?: 'eager' | 'lazy'
  objectFit?: 'initial' | 'cover' | 'contain'
}

function ImageView({
  src,
  alt,
  isFreeSize = false,
  isCircled = false,
  style,
  loading = 'lazy',
  objectFit = 'initial',
}: Props) {
  return (
    <div className={`${styles.componentStyle}`} style={style}>
      <img
        src={src}
        alt={alt}
        loading={loading}
        className={`${isFreeSize ? styles.isFreeSize : styles.defaultSize} ${
          isCircled ? styles.isCircled : ''
        } ${styles[`${objectFit}ObjectFit`]}`}
      />
    </div>
  )
}

export default ImageView
