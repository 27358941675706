import type { UrlObject } from 'node:url'
import type { Route } from 'next'
import Link from 'next/link'
import styles from './IconLinkUnit.module.scss'

function IconLinkUnit<T extends string>({
  text,
  iconSrc,
  href,
}: {
  text: string
  iconSrc: string
  href: Route<T> | UrlObject
}) {
  return (
    <Link className={styles.componentStyle} href={href}>
      <div className={styles.icon}>
        <img src={iconSrc} alt={text} />
      </div>
      <p className={styles.text}>{text}</p>
    </Link>
  )
}

export default IconLinkUnit
