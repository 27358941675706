import * as _dateFns from 'date-fns'
import { ja } from 'date-fns/locale'
import { utcToZonedTime } from 'date-fns-tz'

_dateFns.setDefaultOptions({
  // 日本語設定
  locale: ja,
  // 日曜始まり
  weekStartsOn: 0,
})

export const dateFns = _dateFns

export const Format = {
  date: 'yyyy.MM.dd',
  dateSlash: 'yyyy/MM/dd',
  dateJa: 'yyyy年MM月dd日',
  cardExpired: 'MM/dd',
  dateTime: 'yyyy.MM.dd HH:mm',
  dateTimeSlash: 'yyyy/MM/dd HH:mm:ss',
  dateTimeRequest: 'yyyy-MM-dd HH:mm:ss',
  dateTimeJa: 'yyyy年MM月dd日 HH時mm分',
  dateTimeLotteryJa: 'yyyy年MM月dd日 HH:mm',
  dateTimeJaIncDOW: 'yyyy.MM.dd (E) HH:mm',
}

export type DateFormat = (typeof Format)[keyof typeof Format]

// スペースが入るとエラーになるのを回避するためのnew Date()
export const newDate = (dateStr = '') => {
  if (dateStr) {
    const replacedDateStr = dateStr.replace(/ /g, 'T') // 半角スペースをTに置換
    return new Date(replacedDateStr)
  }
  return utcToZonedTime(new Date(), 'Asia/Tokyo')
}
