'use client'

import styles from './TopSectionTitle.module.scss'
import FaIcon, { faChevronRight } from '@/components/icon/FaIcon/FaIcon'

function TopSectionTitle({
  text,
  onClickChevronRight,
}: {
  text: string
  onClickChevronRight: VoidFunction
}) {
  return (
    <h2 className={styles.titleContainer}>
      <p>{text}</p>
      {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
      <div onClick={onClickChevronRight} className={styles.chevronRight}>
        <FaIcon icon={faChevronRight} />
      </div>
    </h2>
  )
}

export default TopSectionTitle
