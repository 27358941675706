'use client'

import { useState } from 'react'
import type { PostListInfiniteItem } from '../../types'
import PostList from '../PostList/PostList'
import styles from './TopPostList.module.scss'
import AppInductionModal from '@/components/ui/AppInductionModal/AppInductionModal'
import TopSectionTitle from '@/components/ui/TopSectionTitle/TopSectionTitle'

function TopPostList({ posts }: { posts: PostListInfiniteItem[] }) {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <>
      <TopSectionTitle
        text='投稿'
        onClickChevronRight={() => {
          setIsOpen(true)
        }}
      />
      <div className={styles.postListContainer}>
        <PostList posts={posts} />
      </div>
      {/* アプリ遷移モーダル */}
      <AppInductionModal isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </>
  )
}

export default TopPostList
