'use client'

import { useRouter } from 'next/navigation'
import type { EventOpenItem } from '../../types'
import EventListItem from '../EventListItem/EventListItem'
import styles from './TopEventList.module.scss'
import TopSectionTitle from '@/components/ui/TopSectionTitle/TopSectionTitle'

function TopEventList({ events }: { events: EventOpenItem[] }) {
  const router = useRouter()
  const goOpenEventList = () => {
    router.push('/events?tab=open')
  }
  return (
    <>
      <TopSectionTitle
        text='開催中イベント'
        onClickChevronRight={goOpenEventList}
      />
      <div className={styles.eventListContainer}>
        {events.map((event) => (
          <div key={event.eventId}>
            <EventListItem
              eventDetailId={event.eventDetailId}
              bannerUrl={event.bannerUrl}
              eventType={event.type}
              eventEndAt={event.eventEndAt}
              rankings={event.rankings}
            />
          </div>
        ))}
      </div>
    </>
  )
}

export default TopEventList
