'use client'

import { useRouter } from 'next/navigation'
import type { PostListInfiniteItem } from '../../types'
import styles from './PostList.module.scss'
import FaIcon, { faHeart } from '@/components/icon/FaIcon/FaIcon'
import { formatCurrency } from '@/utils/formatters'

function PostList({ posts }: { posts: PostListInfiniteItem[] }) {
  const router = useRouter()
  const goPostDetail = (hashKey: string) => {
    router.push(`/posts/${hashKey}`)
  }
  return (
    <div className={styles.componentStyle}>
      {posts.map((post) => (
        // biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
        <div
          key={post.hashKey}
          className={styles.postItemContainer}
          onClick={() => {
            goPostDetail(post.hashKey)
          }}
        >
          <img
            src={post.imageUrl}
            alt={post.hashKey}
            className={styles.postImage}
          />
          <div className={styles.postInfoContainer}>
            <p className={styles.ellipsisBody}>{post.body}</p>
            <div className={styles.artistNameCheerContainer}>
              <p className={styles.artistName}>{post.artist.name}</p>
              <div className={styles.cheerIconText}>
                <FaIcon icon={faHeart} />
                <p>{formatCurrency(post.cheer)}</p>
              </div>
            </div>
          </div>
          {post.event.id && (
            <div className={styles.eventIconContainer}>
              <img
                src={post.event.iconUrl}
                alt={post.event.title}
                className={styles.eventIcon}
              />
            </div>
          )}
        </div>
      ))}
    </div>
  )
}

export default PostList
