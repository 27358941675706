import clsx from 'clsx'
import type { LiveArtist } from '../../types'
import styles from './ArtistIcon.module.scss'
import firstGachiIcon from '@/assets/images/icons/first-gachi.png'
import newComerIcon from '@/assets/images/icons/new-comer.png'

function ArtistIcon({ artist }: { artist: LiveArtist }) {
  const iconClasses = () => {
    const baseClass = [styles.icon]
    if (artist.isNewcomer) {
      return clsx(baseClass.concat(styles.newComer))
    }
    if (artist.isFirstGachi) {
      return clsx(baseClass.concat(styles.firstGachi))
    }
    return clsx(baseClass.concat(styles.other))
  }

  const additionalIconUrl = () => {
    if (artist.isNewcomer) {
      return newComerIcon.src
    }
    if (artist.isFirstGachi) {
      return firstGachiIcon.src
    }
    return ''
  }

  return (
    <div className={styles.componentStyle}>
      <img className={iconClasses()} src={artist.iconUrl} alt={artist.name} />
      {additionalIconUrl() && (
        <img
          src={additionalIconUrl()}
          alt='icon'
          className={styles.additionalIcon}
        />
      )}
    </div>
  )
}

export default ArtistIcon
