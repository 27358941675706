'use client'

import clsx from 'clsx'
import { useState } from 'react'
import type { OnLiveItem } from '../../types'
import ArtistIcon from '../ArtistIcon/ArtistIcon'
import styles from './OnLiveList.module.scss'
import iconPickUpLive from '@/assets/images/icons/icon-pick-up-live.png'
import AppInductionModal from '@/components/ui/AppInductionModal/AppInductionModal'
import ImageView from '@/components/ui/ImageView/ImageView'

function OnLiveList({
  lives,
  spMaxNum,
  pcMaxNum,
}: {
  lives: OnLiveItem[]
  spMaxNum: number
  pcMaxNum: number
}) {
  const [isOpen, setIsOpen] = useState(false)
  const artistIconClasses = (index: number) => {
    const classes = [styles.artistItemContainer]
    if (index + 1 > spMaxNum) classes.push(styles.exceedSpMaxNum)
    if (index + 1 > pcMaxNum) classes.push(styles.exceedPcMaxNum)

    return clsx(classes)
  }
  return (
    <>
      <div className={styles.componentStyle}>
        <div className={styles.iconContainer}>
          <ImageView src={iconPickUpLive.src} alt='iconPickUpLive' />
        </div>
        <div className={styles.artistIconsContainer}>
          {lives.map((live, index) => (
            // biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
            <div
              className={artistIconClasses(index)}
              key={live.hashKey}
              onClick={() => setIsOpen(true)}
            >
              <ArtistIcon artist={live.artist} />
              <p className={styles.artistName}>{live.artist.name}</p>
            </div>
          ))}
        </div>
        {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
        <p className={styles.moreText} onClick={() => setIsOpen(true)}>
          MORE
        </p>
      </div>
      {/* アプリ遷移モーダル */}
      <AppInductionModal isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </>
  )
}

export default OnLiveList
