'use client'

import { isAfter } from 'date-fns'
import { useRouter } from 'next/navigation'
import {
  type EventRankingItem,
  EventType,
  type EventTypeType,
} from '../../types'
import styles from './EventListItem.module.scss'
import iconClock from '@/assets/images/icons/icon-clock.png'
import ImageView from '@/components/ui/ImageView/ImageView'
import { Format, newDate } from '@/lib/dateFns'
import { formatDateCommon } from '@/utils/formatters'

function EventListItem({
  eventDetailId,
  bannerUrl,
  eventType,
  eventEndAt,
  rankings,
}: {
  eventDetailId: number
  bannerUrl: string
  eventType: EventTypeType
  eventEndAt: string
  rankings?: EventRankingItem[]
}) {
  const router = useRouter()
  const typeEndAtBgColor = () => {
    if (isAfter(newDate(), newDate(eventEndAt))) return 'gray'
    return EventType[eventType].color
  }
  const goEventDetail = (eventDetailId: number) => {
    router.push(`/events/${eventDetailId}`)
  }
  const showRankingImages = rankings && rankings.length > 0
  return (
    // biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
    <div
      className={styles.componentStyle}
      onClick={() => {
        goEventDetail(eventDetailId)
      }}
    >
      <img src={bannerUrl} alt={bannerUrl} className={styles.bannerImg} />
      <div
        className={styles.itemFooterContainer}
        style={{ backgroundColor: `var(--color-${typeEndAtBgColor()})` }}
      >
        <div className={styles.rankArtistIconContainer}>
          {showRankingImages &&
            rankings.map((ranking) => (
              <div
                key={`${eventDetailId}_${ranking.artist.id}`}
                className={styles.rankArtistIcon}
              >
                <ImageView
                  src={ranking.artist.iconUrl}
                  alt={ranking.artist.name}
                />
              </div>
            ))}
        </div>
        <div className={styles.typeEndAtContainer}>
          <p>{EventType[eventType].text}</p>
          <div className={styles.endAtContainer}>
            <img src={iconClock.src} alt='endAt' className={styles.iconClock} />
            <p>{formatDateCommon(eventEndAt, Format.dateTime)}まで</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EventListItem
