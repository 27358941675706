export const BannerDestination = {
  WEB: { value: 'WEB', text: 'WEB' },
  EVENT_DETAIL: { value: 'EVENT_DETAIL', text: 'イベント詳細' },
  ARTIST_PROFILE: { value: 'ARTIST_PROFILE', text: 'アーティストプロフィール' },
} as const
export type BannerDestinationType = keyof typeof BannerDestination

export type BannerItem = {
  id: number
  bannerUrl: string
  displayStartAt: string
  displayEndAt: string
  destination: BannerDestinationType
  web: {
    url: string
  } | null
  event: {
    eventId: number
    eventDetailId: number
    eventStatus: string
  } | null
  artist: {
    id: number
    fanId: number
  } | null
}
